import React from 'react';
import Gallery from "react-photo-gallery";
import {
  isBrowser,
} from "react-device-detect";

import {
  AppBar,
  Toolbar,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  GridList,
  GridListTile,
} from "@material-ui/core";
import {
  Phone,
  MailOutline,
  Schedule,
  RoomOutlined,
  PhotoLibrary,
} from "@material-ui/icons"

import './App.css';
import { makeStyles } from '@material-ui/core/styles';

const PALETTE = {
  bar: "#333333",
  title: "#eeeeee",
  card: "#808080"
}

const PROJECTS = [
  {
    name: "Morguard",
    address: "50 Portland Street",
    count: 4
  },
  {
    name: "Crown Realty",
    address: "11 Allstate Parkway",
    count: 3
  },
  {
    name: "Markham Square Condos Showroom",
    address: "4461 Highway 7 East",
    count: 4
  },
  {
    name: "VersaPay",
    address: "18 King Street East",
    count: 2
  },
  {
    name: "V+Homes",
    address: "7250 Keele Street",
    count: 5
  },
  {
    name: "Crown Properties",
    address: "5255 Yonge Street",
    count: 5
  },
  {
    name: "Equity Financial",
    address: "100 King Street West",
    count: 4
  },
]

function getTimestamp() {
  return Math.floor(
    Date.now() / 100000
  )
}

function SingleLineGridList(props) {
  const classes = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: PALETTE.bar,
    },
    gridList: {
      width: '100%',
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }))()

  const tileData = []
  for (var i = 0; i < PROJECTS[props.id].count; i++) {
    tileData.push(process.env.PUBLIC_URL + `/static/${props.id}/${i}.JPG?t=${getTimestamp()}`)
  }

  let rows = 1
  if (isBrowser) {
    rows = 3
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {tileData.map(tile => (
          <GridListTile key={tile} rows={rows}>
            <img src={tile} alt="" />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}


class CustomCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleDialog = (_e) => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.state.open} maxWidth="xl" fullWidth>
          <DialogContent style={{ backgroundColor: PALETTE.bar }}>
            <SingleLineGridList id={this.props.id} />
          </DialogContent>
          <DialogActions style={{ backgroundColor: PALETTE.bar }}>
            <Button style={{ margin: "auto" }} variant="contained" color="secondary" onClick={this.handleDialog}> Close </Button>
          </DialogActions>
        </Dialog>

        <Card>
          <CardActionArea onClick={this.handleDialog}>
            <CardMedia
              image={process.env.PUBLIC_URL + `/static/${this.props.id}/0.JPG?t=${getTimestamp()}`}
              style={{
                height: 300
              }}
            />
            <CardContent
              style={{
                height: 100,
                backgroundColor: PALETTE.card
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Typography gutterBottom variant="h5" component="h4" style={{ float: "left" }}>
                    {PROJECTS[this.props.id].name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <PhotoLibrary style={{ float: "right", marginTop: 3 }} />
                  <Typography variant="h6" component="p" style={{ float: "right" }}>
                    {this.props.count}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Typography variant="body2" component="p">
                {PROJECTS[this.props.id].address}
              </Typography>
            </CardActions>
          </CardActionArea>
        </Card>
      </React.Fragment>
    )
  }
}


function App() {
  var cardList = []

  for (var i = 0; i < PROJECTS.length; i++) {
    cardList.push(
      <Grid item xs={12} sm={6} md={3}>
        <CustomCard id={i} count={PROJECTS[i].count} />
      </Grid>
    );
  }

  const photos = [
    {
      src: process.env.PUBLIC_URL + `/static/residential/0.JPG?t=${getTimestamp()}`,
      width: 4,
      height: 3
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/1.JPG?t=${getTimestamp()}`,
      width: 5,
      height: 4
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/2.JPG?t=${getTimestamp()}`,
      width: 4,
      height: 3
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/3.JPG?t=${getTimestamp()}`,
      width: 3,
      height: 4
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/4.JPG?t=${getTimestamp()}`,
      width: 3,
      height: 4
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/5.JPG?t=${getTimestamp()}`,
      width: 4,
      height: 3
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/6.JPG?t=${getTimestamp()}`,
      width: 4,
      height: 3
    },
    {
      src: process.env.PUBLIC_URL + `/static/residential/7.JPG?t=${getTimestamp()}`,
      width: 5,
      height: 3
    }
  ];

  return (
    <div className="App">
      <AppBar position="fixed" style={{ backgroundColor: PALETTE.bar, boxShadow: "none" }}>
        <Toolbar>
          <h3 style={{ color: PALETTE.title }}>
            WINCOR INTERIORS LIMITED
            </h3>
        </Toolbar>
      </AppBar>

      <br />
      <br />
      <h1>Our mission is to provide excellent quality and value.</h1>
      <h2>Wincor Interiors is a well established millwork and stone contractor.<br/>We work with clients to produce timely projects with lasting quality.</h2>
      <br />

      <div style={{ backgroundColor: PALETTE.title, marginLeft: "-12.5%", marginRight: "-12.5%" }}>
        <br />
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <h1>Projects</h1>

          <Grid container spacing={2} justify="center">
            {cardList}
          </Grid>

          {/* <br />
          <br />

          <h1>Residential Gallery</h1>
          <Gallery photos={photos} /> */}
        </div>
        <br />
        <br />
      </div>

      <h1>Contact Info</h1>

      <Grid container spacing={10}>
        <Grid item sm={12} md={6}>
          <iframe title="map" style={{}} id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.58521105269!2d-79.2788892!3d43.7814684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d19d54dc9c61%3A0x2db404e11146b85a!2s50%20Weybright%20Ct%20%2338%2C%20Scarborough%2C%20ON%20M1S%205A8!5e0!3m2!1sen!2sca!4v1671124656518!5m2!1sen!2sca"
            scrolling="no" marginheight="0" marginwidth="0" width="100%" height="350" frameborder="0"></iframe>
        </Grid>

        <Grid item sm={12} md={6}>
          <h3>
            {<RoomOutlined style={{ marginBottom: -5 }} />}  50 Weybright Court, Unit 38<br />
            <span style={{ marginLeft: 28 }}>Scarborough, ON</span><br />
            <span style={{ marginLeft: 28 }}>M1S 5A8</span><br /><br />
            {<Phone style={{ marginBottom: -5 }} />} 416.878.8588<br /><br />
            {<MailOutline style={{ marginBottom: -5 }} />} info@wincorinteriors.com<br /><br />
            {<Schedule style={{ marginBottom: -5 }} />} Monday to Friday, 8:30 AM to 5 PM<br /><br />
          </h3>
        </Grid>
      </Grid>

      <br />
      <br />
    </div>
  );
}

export default App;
